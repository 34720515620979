import * as React from "react";
import { Heading, Paragraph, Paragraphs } from "../../components/atoms/typography";
import { Responsive } from "../../components/gls/responsive";
import { StretchSpacer, VerticalSpacer } from "../../components/gls/spacer";
import { LearnMore } from "../../components/templates/common/button";
import { Section } from "../../components/templates/page/section/section";

export const HomeSectionIntro = () => (
  <Section>
    <Responsive>
      <Heading fontSize={"4xl"} maxWidth={600}>
        Contributing to a renewable hydrogen future
      </Heading>
      <StretchSpacer />
      <Paragraphs>
        <Paragraph maxWidth={450}>
          Hydrogen Fuels Australia (H2FA) is launching Australia’s first modular hydrogen production and integrated FCEV
          refuelling operation at its greenfield facility in Truganina, Victoria. H2FA plans to expand its reach,
          providing refuelling of both heavy and light vehicles throughout Australia.
        </Paragraph>
        <Paragraph maxWidth={450}>
          Founded on environmentally sustainable and low impact concepts, H2FA’s operation uses its own electrolysis
          assets (in island mode) to convert renewable power into green hydrogen.
        </Paragraph>
        <Paragraph maxWidth={450}>
          H2FA’s mission is to provide 100% renewable green hydrogen to Australia’s transport industry - and leave a
          minimal carbon footprint.
        </Paragraph>
        <VerticalSpacer />
        <LearnMore />
      </Paragraphs>
    </Responsive>
  </Section>
);
