import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
// @ts-ignore
import ModuleCompressor from "../../assets/images/modules/compressor.png";
// @ts-ignore
import ModuleDispenser from "../../assets/images/modules/dispenser.png";
// @ts-ignore
import ModuleElectrolyser from "../../assets/images/modules/electrolyser.png";
// @ts-ignore
import ModuleSolar from "../../assets/images/modules/solar.png";
// @ts-ignore
import ModuleStorage from "../../assets/images/modules/storage.png";
// @ts-ignore
import ModuleWater from "../../assets/images/modules/water.png";
import { Carousel } from "../../components/atoms/carousel";
import { ResponsiveLayout } from "../../components/gls/responsive-layout";
import { Section } from "../../components/templates/page/section/section";
import { SectionInfoText } from "../../components/templates/page/section/section-info";
import { SectionInfoSlide } from "../../components/templates/page/section/section-slide";

export const HomeSectionModular = () => (
  <ResponsiveLayout
    mobile={() => (
      <Section backgroundColor={"icywaters"} py={"xl"}>
        <ModularCarousel />
      </Section>
    )}
    desktop={() => (
      <SectionInfoSlide
        height={120}
        text={<SectionInfoText maxWidth={600}>Elegant and modular, leaving a light footprint</SectionInfoText>}
        staticImage={
          <StaticImage
            src={`../../assets/images/infrastructure/modular-equipments.png`}
            placeholder="blurred"
            objectFit={"contain"}
            width={900}
            alt="Hydrogen production pipeline"
          />
        }
      />
    )}
  />
);

const ModularCarousel = () => (
  <Carousel
    items={items}
    autoPlay
    autoPlayInterval={3000}
    infinite
    autoHeight
    disableButtonsControls
    disableDotsControls
    touchTracking
  />
);

const CarouselItem = styled.div({
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 150,
});

const ModuleImage = styled.img({
  height: 150,
});

const items = [
  <CarouselItem>
    <SectionInfoText>Elegant and modular, leaving a light footprint</SectionInfoText>
  </CarouselItem>,
  <CarouselItem>
    <ModuleImage src={ModuleWater} />
  </CarouselItem>,
  <CarouselItem>
    <ModuleImage src={ModuleSolar} />
  </CarouselItem>,
  <CarouselItem>
    <ModuleImage src={ModuleElectrolyser} />
  </CarouselItem>,
  <CarouselItem>
    <ModuleImage src={ModuleStorage} />
  </CarouselItem>,
  <CarouselItem>
    <ModuleImage src={ModuleCompressor} />
  </CarouselItem>,
  <CarouselItem>
    <ModuleImage src={ModuleDispenser} />
  </CarouselItem>,
];
