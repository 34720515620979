import * as React from "react";
import { Box, BoxProps, Color } from "../../design-system";

export type SectionProps = {
  children: React.ReactNode;
  backgroundColor?: Color;
} & Omit<BoxProps, "component" | "background">;

export const Section = ({ children, backgroundColor, ...delegated }: SectionProps) => {
  return (
    <Box as={"section"} background={backgroundColor ?? "transparent"}>
      <Box style={{ margin: "0 auto", width: "100%" }} py={"xxl"} px={"md"} maxWidth={"container.lg"} {...delegated}>
        {children}
      </Box>
    </Box>
  );
};
