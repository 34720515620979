import * as React from "react";
import styled from "styled-components";
import { Theme } from "../../../core/app.theme";
import { Section } from "./section";

export const SectionInfoSlide = (props: { height: number; text: React.ReactNode; staticImage: React.ReactNode }) => (
  <Section backgroundColor={"icywaters"}>
    <SlideWrapper height={props.height}>
      <SlideImageWrapper id={"slide-image"}>{props.staticImage}</SlideImageWrapper>
      <SlideTextWrapper id={"slide-text"}>{props.text}</SlideTextWrapper>
    </SlideWrapper>
  </Section>
);

const SlideWrapper = styled.section<{ height: number }>((props) => ({
  position: "relative",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  transition: "all .4s",
  ["@keyframes slide-in"]: {
    "100%": {
      transform: "translateX(0%)",
    },
  },
  ["@keyframes slide-out"]: {
    "50%": {
      transform: "translateX(100%)",
    },
    "100%": {
      transform: "translateX(200%)",
    },
  },
  ["& #slide-image"]: {
    position: "absolute",
    transform: "translateX(-200%)",
  },
  ["& #slide-text"]: {
    transform: "translateX(0)",
  },
  [Theme.media("lg")]: {
    height: props.height,
    ":hover": {
      ["& #slide-image"]: {
        position: "static",
        animation: "slide-in 0.5s forwards",
      },
      ["& #slide-text"]: {
        display: "none",
      },
    },
  },
}));

const SlideImageWrapper = styled.div({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const SlideTextWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});
