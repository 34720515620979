import * as React from "react";
import { navigate } from "gatsby";
import { PageLinks } from "../../../components/templates/page/page.data";
import { Button } from "../../../design-system";

export const LearnMore = () => (
  <Button style={{ width: 180 }} textTransform={"uppercase"} onClick={() => navigate(PageLinks.aboutUs)}>
    Learn more
  </Button>
);
