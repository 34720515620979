import * as React from "react";
import { navigate } from "gatsby";
import { Button } from "../../atoms/button";
import { PageLinks } from "../page/page.data";

export const LearnMore = () => (
  <Button type={"button"} style={{ width: 160 }} onClick={() => navigate(PageLinks.aboutUs)}>
    LEARN MORE
  </Button>
);
