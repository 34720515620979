import React from "react";
import { desktop, mobile } from "./responsive-layout.css";

/**
 * TODO Use user agent to determine layout
 */
export const ResponsiveLayout = (props: { nodes: React.ReactNode[] }) => {
  return (
    <>
      <div className={mobile}>{props.nodes[0]}</div>
      <div className={desktop}>{props.nodes[1]}</div>
    </>
  );
};
