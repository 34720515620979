import * as React from "react";
import { Paragraph, Text } from "../../components/atoms/typography";
import { Responsive } from "../../components/gls/responsive";
import { StretchSpacer, VerticalSpacer } from "../../components/gls/spacer";
import { Vertical } from "../../components/gls/vertical";
import { LearnMore } from "../../components/templates/common/button";
import { Section } from "../../components/templates/page/section/section";

export const HomeSectionAdvantage = () => (
  <Section maxWidth={"md"}>
    <Responsive>
      <Text fontSize={"md"} fontFamily={"monument"} maxWidth={300} fontWeight={"book"} textTransform={"uppercase"}>
        The advantage of green hydrogen
      </Text>
      <StretchSpacer />
      <Vertical>
        <Paragraph maxWidth={"400px"}>
          Hydrogen produced from renewable sources can contribute to a sustainable and circular economy.
        </Paragraph>
        <Paragraph maxWidth={"400px"}>
          H2FA’s hydrogen will be produced from curtailed power - power which is stranded - and often wasted because it
          can’t be put into the grid. H2FA will re-home it.
        </Paragraph>
        <VerticalSpacer space={"lg"} />
        <LearnMore />
      </Vertical>
    </Responsive>
  </Section>
);
