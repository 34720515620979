import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Page } from "../components/templates/page/page";
import { PageLoader } from "../components/templates/page/page-loader";
import { SectionInfo, SectionInfoQuoteText } from "../components/templates/page/section/section-info";
import { SectionInfoSlide } from "../components/templates/page/section/section-slide";
import { HomeSectionAdvantage } from "../sections/index/advantage.section";
import { HomeHeroVideo } from "../sections/index/hero-video";
import { HomeSectionIntro } from "../sections/index/intro.section";
import { HomeSectionModular } from "../sections/index/modular.section";
import { HomeSectionUserExperience } from "../sections/index/user-experience.section";
import { HydrogenUtilitiesSection } from "../templates/home/components/hydrogen-utilities.section";

const IndexPage = () => (
  <>
    <PageLoader />
    <Page title={"Home"}>
      <HomeHeroVideo />
      <HomeSectionIntro />
      <SectionInfoSlide
        height={320}
        text={
          <SectionInfoQuoteText
            quote={
              "Society won’t settle for companies that preach green but don’t manage their carbon footprints, or financial institutions who can’t tell us whether our money is on the right or wrong side of climate history."
            }
            author={"(Mark Carney: Reith Lectures, December, 2020)"}
          />
        }
        staticImage={
          <StaticImage
            src={`../assets/images/infrastructure/full-site.png`}
            placeholder="blurred"
            objectFit={"fill"}
            height={300}
            alt="Hydrogen production pipeline"
          />
        }
      />
      <HydrogenUtilitiesSection />
      <SectionInfo>
        <SectionInfoQuoteText
          quote={
            "Heavy and high use commercial vehicles represent a natural starting point for Hydrogen refuelling… Businesses large and small will scramble to decarbonise their supply chain"
          }
          author={"Francesco Ceravolo, Managing Director, H2FA"}
        />
      </SectionInfo>
      <HomeSectionUserExperience />
      <SectionInfo>
        <SectionInfoQuoteText
          quote={
            "It's a way that you demonstrate that you care for the people.… I think we see ourselves as having a civic responsibility to do that. It's important. It's right…"
          }
          author={"(Jonathan Ive: On the importance of design, The Telegraph, 2012)"}
        />
      </SectionInfo>
      <HomeSectionAdvantage />
      <StaticImage
        src={`../assets/images/infrastructure/site-rectangle.png`}
        layout="fullWidth"
        placeholder="blurred"
        objectFit={"fill"}
        alt=""
      />
      <HomeSectionModular />
    </Page>
  </>
);

export default IndexPage;
