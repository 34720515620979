// @ts-ignore
import * as React from "react";
import styled from "styled-components";
// @ts-ignore
import LogoAnimationDesktop from "../../assets/video/hero-animation-desktop.mp4";
// @ts-ignore
import LogoAnimationMobile from "../../assets/video/hero-animation-mobile.mp4";
import { Theme } from "../../components/core/app.theme";
import { ResponsiveLayout } from "../../components/gls/responsive-layout";

export const HomeHeroVideo = () => (
  <ResponsiveLayout
    mobile={() => (
      <Video autoPlay={"autoplay" as any} loop muted playsInline>
        <source src={LogoAnimationMobile} type="video/mp4" />
      </Video>
    )}
    desktop={() => (
      <Video autoPlay={"autoplay" as any} loop muted playsInline>
        <source src={LogoAnimationDesktop} type="video/mp4" />
      </Video>
    )}
  />
);

const Video = styled.video({
  width: "100%",
  objectFit: "cover",
  height: 400,
  [Theme.media("lg")]: {
    height: 650,
  },
});
