import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Paragraph, Text } from "../../components/atoms/typography";
import { Vertical } from "../../components/gls/vertical";
import { LearnMore } from "../../components/templates/common/button";
import { Section } from "../../components/templates/page/section/section";

export const HomeSectionUserExperience = () => (
  <Section maxWidth={"md"}>
    <Vertical>
      <Text fontSize={"md"} fontFamily={"monument"} fontWeight={"book"} textTransform={"uppercase"}>
        A Unique User Experience
      </Text>
      <StaticImage src={`../../assets/images/infrastructure/site.png`} placeholder="blurred" alt="Hydrogen site" />
      <Paragraph maxWidth={"400px"}>
        Our Truganina site will be a drawcard for the filling of vehicles, education and the future of how we use
        resources. Utilising innovative and imaginative industrial design we aim to facilitate the growth and the future
        of hydrogen fuel.
      </Paragraph>
      <LearnMore />
    </Vertical>
  </Section>
);
